.ContainerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Ajuste para distribuir o conteúdo */
    align-items: center;
    background-color: aliceblue;
    padding: 0 20px; /* Adiciona padding nas laterais para não colar os elementos nas bordas */
    height: 15%;
  }
  
  .logo {
    margin-left: 30px;
    height: 95%; /* Ajuste conforme necessário */
  }
  .logobox{
    display: flex;
    width: 33%;
    height: 100%;
  }
  
  .icons {
    height: 40px;
    margin: 0 10px; /* Ajusta a margem para os ícones */
  }
  
  /* Classe adicionada para centralizar os ícones de redes sociais */
  .social-icons-container {
    display: flex;
    justify-content: center; /* Centraliza os ícones horizontalmente */
    align-items: center; /* Centraliza os ícones verticalmente */
    width: 33%; /* Permite que o container cresça conforme necessário */
  }
  .icons:hover{
    height:  55px;
  }
  
  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around; /* Mantém se deseja espaçamento uniforme */
    gap: 10px;
    width: 33%;
    flex-wrap: wrap;
  }

  span:hover {
    color:#086d8b;
  }
  
  a span {
    text-decoration: none !important; /*Remove o sublinhado */
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
  .footer {
    height: 3%;
    width: 100%;
    background-color: blueviolet;
  }
  a[href] {
    text-decoration: none !important;
  }

/* CSS Reajustado para dispositivos móveis */

@media (max-width: 768px) {
  .ContainerHeader {
    height: 20%;
      flex-direction: column; /* Muda para coluna para estruturar melhor os elementos */
      align-items: center; /* Centraliza os itens horizontalmente */
      padding: 10px; /* Ajusta o padding para manter tudo contido */
  }

  .logobox {
      display: none;
  }

  .logo {
      height: auto; /* Mantém a altura do logo automática para proporção */
      max-width: 50%; /* Limita a largura máxima do logo para evitar que ele fique muito grande */
      max-height: 60px; /* Limita a altura máxima do logo */
  }

  .nav {
      order: 2;
      width: 100%; /* Permite que a navegação ocupe a largura total */
      justify-content: space-around; /* Distribui os links de navegação uniformemente */
  }

  .social-icons-container {
      order: 3;
      width: 100%; /* Garante que os ícones de redes sociais ocupem a largura total */
      margin-top: 10px; /* Adiciona um espaço acima dos ícones de redes sociais */
  }

  .icons {
      max-height: 30px; /* Ajusta o tamanho dos ícones para manter dentro do componente */
      margin: 0 5px; /* Ajusta a margem entre os ícones */
  }
}
