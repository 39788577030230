.App {
    display: flex;
    text-align: center;
    background-color: #c6e5ea;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    background-image: url('../icons/fundo3.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .menusup {
    display: flex;  
    height: 15%;
    width: 100%;
    background-color: #1ebe5f;
  }
  .interact {
    display:flex;
    flex-direction: row;
    align-items: center;
    background-color: #086d8b;
    flex: 1;  
  }
  .interact{
    margin: 20px;
    border-width: 5px;
  }
  .App-header {
    background-color: #c6e5ea;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 150px;
    align-items: center;
    text-align: center;
    margin-top: 230px; /* para centralizar na tela*/
    /* Animação */
    opacity: 0; /* Começa invisível */
    transform: translateY(100px); /* Desloca para baixo inicialmente */
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  p {
    font-size: 20px;
  }
  
  .logo {
    height: 150px;
  }
  
  .whatsapp-button {
    background-color: #086d8b;
    color: white;
    padding: 10px 20px;
    border: none;
    text-decoration: none;
    margin-top: 20px;
    border-radius: 5px;
    height: 25px;
    width: 100px;
    display: inline-flex; /* Para alinhar o ícone com o texto */
    align-items: center; /* Centraliza verticalmente o ícone com o texto */
    gap: 10px; /* Espaço entre o ícone e o texto */
  }
  
  .whatsapp-button:hover {
    background-color: #1ebe5f; /* Verde um pouco mais escuro para o hover */
  }
  
  .App-background {
    background-image: url('../icons/fundo1.jpg');
    background-size: cover;
    background-position: center;
  }